import * as React from "react";
import { getBexApiContext, Context } from "bernie-context";
import { AnalyticsStore, PageStore } from "bernie-plugin-mobx";
import { inject } from "mobx-react";
import { ReasonsToBelieve } from "./ReasonsToBelieve";
import { getIdentity } from "components/shared/CustomerNotifications/NotificationsWithDialog/NotificationQueryUtils";
import { NotificationLocationOnPage } from "__generated__/typedefs";

interface ReasonsToBelieveWrapperProps {
  context?: Context;
  analytics?: AnalyticsStore;
  location: NotificationLocationOnPage;
  page?: PageStore;
  className?: string;
  version?: CovidBannerVersions;
  hasSubtitle?: boolean;
}

export enum CovidBannerVersions {
  ONE = "one",
  TWO = "two",
  THREE = "three",
  FOUR = "four",
}

export const ReasonsToBelieveWrapper: React.FC<ReasonsToBelieveWrapperProps> = inject(
  "analytics",
  "context",
  "page"
)((props: ReasonsToBelieveWrapperProps) => {
  const {
    analytics,
    context,
    location,
    page,
    className = "",
    version = CovidBannerVersions.FOUR,
    hasSubtitle = false,
  } = props;

  if (!page || !page.pageId || !context) {
    return null;
  }

  const identity = getIdentity(page);

  /* istanbul ignore next */
  const analyticsCallback = (rfrr: string, linkName: string | null) => {
    analytics?.trackEvent(rfrr, linkName || "RFRR Action Event", analytics.omnitureData, null, true);
  };

  return (
    <ReasonsToBelieve
      inputs={{
        context: getBexApiContext(context),
        lineOfBusiness: identity.lineOfBusiness,
        funnelLocation: identity.funnelLocation,
        location,
      }}
      analyticsCallback={analyticsCallback}
      className={className}
      version={version}
      hasSubtitle={hasSubtitle}
    />
  );
});
