import * as React from "react";
import { useQuery } from "@apollo/client";
import { observer } from "mobx-react";
import { ClientChatbotStore } from "src/stores/chatbotStore/ClientChatbotStore";
import { UitkLayoutFlex, UitkLayoutFlexProps, UitkLayoutFlexItemProps } from "uitk-react-layout-flex";
import { UitkHeading, UitkSubheading } from "uitk-react-text";
import { UitkSpacing } from "uitk-react-spacing";
import {
  getLineOfBusiness,
  getFunnelLocation /* , sendAnalytics */,
} from "../CustomerNotifications/NotificationsWithDialog/NotificationQueryUtils";
import { BlossomNotificationDocument, ContextInput, NotificationLocationOnPage } from "__generated__/typedefs";
import { ReasonsToBelieveCard } from "./ReasonsToBelieveCard";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";
import { CovidBannerVersions } from "./ReasonsToBelieveWrapper";

interface ReasonsToBelieveProps {
  inputs: {
    context: ContextInput;
    lineOfBusiness: string;
    funnelLocation: string;
    location: NotificationLocationOnPage;
  };
  analyticsCallback: (rfrr: string, linkname: string | null) => void;
  className?: string;
  chatbot?: ClientChatbotStore;
  version: CovidBannerVersions;
  hasSubtitle?: boolean;
}

export const ReasonsToBelieve = observer((props: ReasonsToBelieveProps) => {
  const queryVariables = {
    context: props.inputs.context,
    notificationLocation: props.inputs.location,
    lineOfBusiness: getLineOfBusiness(props.inputs.lineOfBusiness),
    funnelLocation: getFunnelLocation(props.inputs.funnelLocation),
  };
  const { className = "" } = props;
  const { data, error, loading } = useQuery(BlossomNotificationDocument, {
    variables: queryVariables,
  });

  if (error || loading || !data || !data?.notification || !data.notification.inlineNotification.title) {
    return null;
  }

  const { title, links, body } = data.notification.inlineNotification;

  const heading = title?.items[0].text;
  const subHeading = title?.items[1]?.text || "";

  const getContent = (
    direction: UitkLayoutFlexProps["direction"],
    minWidth: UitkLayoutFlexItemProps["minWidth"],
    maxWidth: UitkLayoutFlexItemProps["maxWidth"]
  ) => (
    <UitkSpacing padding={{ blockstart: "six" }}>
      <UitkLayoutFlex space="three" direction={direction} justifyContent="space-between">
        {props.version === CovidBannerVersions.ONE
          ? links?.map((link, index) => (
              <ReasonsToBelieveCard
                key={`rtb-card-${index}`}
                analyticsCallback={props.analyticsCallback}
                link={link}
                index={index}
                minWidth={minWidth}
                maxWidth={maxWidth}
                version={props.version}
              />
            ))
          : body?.map((part, index) => (
              <ReasonsToBelieveCard
                key={`rtb-card-${index}`}
                analyticsCallback={props.analyticsCallback}
                body={part}
                index={index}
                minWidth={minWidth}
                maxWidth={maxWidth}
                version={props.version}
              />
            ))}
      </UitkLayoutFlex>
    </UitkSpacing>
  );

  return (
    <div className={className} data-testid="reasons-to-believe">
      <section>
        <div>
          <UitkHeading tag="h2" size={3}>
            {heading}
          </UitkHeading>
          {props.hasSubtitle && <UitkSubheading>{subHeading}</UitkSubheading>}
          <Viewport>
            <ViewSmall>{getContent("column", "full_width", "full_width")}</ViewSmall>
            <ViewMedium>{getContent("row", "third_width", "third_width")}</ViewMedium>
          </Viewport>
        </div>
      </section>
    </div>
  );
});
